import { UALJs } from "ual-plainjs-renderer";
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";
import { Wombat } from 'wombat-ual'
/*global UAL:writable,loggedInUser:writable*/
/*eslint no-undef: "error"*/

// login functio
export default class LoginService {
    constructor() {
        this.chain = {
            chainId: process.env.VUE_APP_CHAIN_ID,
            rpcEndpoints: [{
                protocol: "https",
                host: process.env.VUE_APP_CHAIN_URL,
                port: '443',
            }, ],
        };
        this.appName = process.env.VUE_APP_NAME;
        this.wax = new Wax([this.chain], { appName: this.appName });
        this.anchor = new Anchor([this.chain], { appName: this.appName });
        this.wombat = new Wombat([this.chain], { appName: this.appName });
        const myAppRoot = {
            containerElement: document.getElementById('ual-div')
        }

        // validate authentication
        this.setUal(myAppRoot);
        (async () => {
            await UAL.init();
        })()
    }
    setUal(myAppRoot) {
        UAL = new UALJs(this.loginCallBack, [this.chain], this.appName, [this.wax, this.anchor,this.wombat], myAppRoot);
    }
    loginCallBack(users) {
        loggedInUser = users[0];
        console.log(loggedInUser);
        if (loggedInUser.session) {
            localStorage.setItem("session-authenticator", "Anchor")
        } else if (loggedInUser.scatter) {
            localStorage.setItem("session-authenticator", "Wombat")
        } else {
            localStorage.setItem("session-authenticator", "Wax")
        }
        localStorage.setItem('wax_user', loggedInUser.accountName)
        localStorage.setItem(UALJs.SESSION_ACCOUNT_NAME_KEY, loggedInUser.accountName)
        window.location.href = '/dashboard'
    }
    handle_login() {
        const myAppRoot = {
            containerElement: document.getElementById('ual-div')
        }
        this.setUal(myAppRoot);
        (async() => {
            await UAL.init();
        })()
        return UAL
    }
    getLoginUser() {
        const activeUser = localStorage.getItem("wax_user");
        if (UAL) {
            if (localStorage.getItem("session-authenticator") == "Wax") {
                UAL.loginUser(this.wax, activeUser)
            } else if (localStorage.getItem("session-authenticator") == "Wombat") {
                UAL.loginUser(this.wombat, activeUser)
            }else{
                UAL.loginUser(this.anchor, activeUser)
            }
            return UAL
        }
        return UAL
    }

}