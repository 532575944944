<template>
  <div class="login-wrapper">
    <div class="login-content">
      <div class="rpc-server-wrapper">
        <RpcServer />
      </div>
      <div class="login-button">
        <a
          type="button"
          class="theme_color text-uppercase fw-bold"
          @click.prevent="handle_login()"
          >Login</a
        >
      </div>
    </div>
    <div id="ual-div"></div>
  </div>
</template>

<script>
/*global UAL:writable*/
/*eslint no-undef: "error"*/
// @ is an alias to /src
import LoginService from "../services/LoginService";
import RpcServer from "../components/RpcServer.vue";
export default {
  name: "Login",
  components: {
    RpcServer,
  },
  data() {
    return {
      ual: null,
      showPopup: false,
    };
  },
  inheritAttrs: false,
  mounted() {
    localStorage.clear();
    let elements = document.body.getElementsByClassName("ual-button-gen");
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
    new LoginService();
    this.ual = UAL;
    // this.ual.init();
    // var [buttonUAL] = document.body.getElementsByClassName("ual-button-gen");
    // buttonUAL.click();
  },
  methods: {
    handle_login: function () {
      var [buttonUAL] = document.body.getElementsByClassName("ual-button-gen");
      if (this.ual) {
        this.ual.init();
        buttonUAL.click();
        let elements = document.body.getElementsByClassName("ual-button-gen");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      } else {
        this.ual.init();
        buttonUAL.click();
        let elements = document.body.getElementsByClassName("ual-button-gen");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },
  },
};
</script>

<style>
.ual-button-gen {
  display: none !important;
}
#ual-modal-selection-content {
  position: relative;
}
.ual-modal-close {
  position: absolute;
  top: 11px;
  right: 17px;
}
#ual-button {
  display: none !important;
}
.ual-auth-text {
  display: inline-block !important;
  padding: 20px 4px 17px 10px !important;
  font-size: 10px !important;
  width: 137px !important;
}
.fa-chevron-right {
  font-size: 16px !important;
  float: right;
  margin: 12px 6px;
}
#ual-modal-selection-content {
  margin-top: 10px !important;
}
.ual-auth-icon-wrapper {
  padding: 2px 10px 8px 10px !important;
  overflow: hidden;
}
#ual-modal #ual-modal-selection-content {
  font-family: "Source Sans Pro", sans-serif;
  background-color: rgba(220, 233, 233, 0.9) !important;
  border: 1px solid transparent !important;

  /* border-radius: 6px;
  width: 642px; */
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<style scoped>
.login-wrapper {
  width: 100%;
  height: 100vh;
}
.login-content {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
}
.login-button a {
  padding: 15px 16px;
  background-image: url("/images/menu-button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 25px;
  font-size: 18px;
  width: 230px;
  text-align: center;
}
</style>
