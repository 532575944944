<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 p-0">
        <Sidebar></Sidebar>
      </div>
      <div class="col-lg-10">
        <div class="mainCard mt-5 position-relative">
          <div class="row">
            <div class="col-lg-4">
              <div class="cardImgSection position-relative">
                <div class="img_btn">
                  <div class="position-relative imgbtn_inner">
                    <img
                      src="/images/begin-btn.png"
                      alt="begin-btn"
                      class="img-fluid"
                    />
                    <span class="text-uppercase btnText theme_color"
                      >Trail of Tears</span
                    >
                  </div>
                </div>
                <img
                  src="/images/tear-ship.png"
                  alt="tear-ship"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-lg-8">
              <div class="progressbar">
                <img
                  src="/images/progress-bar-value.png"
                  alt="progress-bar-value"
                  class="img-fluid"
                />
              </div>
              <div class="innerFrame mt-3">
                <h2 class="theme_color text-uppercase fw-bold mb-4">
                  The Search For The Lost Tear
                </h2>
                <p class="text-white pb-3">
                  Players will embark on a journey to the Eye of the Ocean, a
                  vast expanse of water that is said to hold the first of the
                  lost tears. They will have to navigate treacherous currents
                  and avoid dangerous sea creatures in order to find the first
                  tear.
                </p>
              </div>
              <div class="innerFrame mt-lg-3 mt-md-3">
                <div
                  class="
                    d-lg-flex d-md-flex d-inline-block
                    justify-content-between
                    typesMobile_design
                  "
                >
                  <div class="text-uppercase fw-bold">
                    <p class="theme_color mb-0">Type</p>
                    <p class="text-white">Trail of Tears</p>
                  </div>
                  <div class="text-uppercase fw-bold">
                    <p class="theme_color mb-0">Area</p>
                    <p class="text-white">Eye of the Ocean</p>
                  </div>
                  <div class="text-uppercase fw-bold">
                    <p class="theme_color mb-0">Level</p>
                    <p class="text-white">1</p>
                  </div>
                  <div class="text-uppercase fw-bold">
                    <p class="theme_color mb-0">Distance</p>
                    <p class="text-white">75 Nm</p>
                  </div>
                  <div class="text-uppercase fw-bold">
                    <p class="theme_color mb-0">Reward</p>
                    <p class="text-white">N 1000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mainCard mt-5 cardSpace">
          <div class="row mb-lg-5 mb-md-5 mb-0 mt-4">
            <div class="col-12 ps-lg-0 ps-md-0">
              <div class="innerFrame frame_main">
                <div class="row">
                  <div class="col-lg-3 col-12">
                    <div class="story_img">
                      <div class="cardImgSection img_small">
                        <img
                          src="/images/tear-ship.png"
                          alt="tear-ship"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-9 col-12">
                    <h2 class="theme_color text-uppercase fw-bold mb-0">
                      The Heat of the sun
                    </h2>
                    <p class="text-white">
                      This is the description of the area, including if its in
                      the water, land, cavern, etc. and any other warnings you
                      may need.
                    </p>
                    <div
                      class="
                        d-lg-flex d-inline-block
                        justify-content-between
                        typesMobile_design
                        mt-1
                        w-100
                      "
                    >
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Type</p>
                        <p class="text-white">Name Type</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Area</p>
                        <p class="text-white">Name Area</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Tribe</p>
                        <p class="text-white">Name Tribe</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Level</p>
                        <p class="text-white">1</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Distance</p>
                        <p class="text-white">
                          123 <span class="text-lowercase">ml</span>
                        </p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Reward</p>
                        <p class="text-white">N 1000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-5 mb-lg-5 mb-md-5 mb-0 mt-4">
            <div class="col-12 ps-0">
              <div class="innerFrame frame_main">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="story_img">
                      <div class="cardImgSection img_small">
                        <img
                          src="/images/tear-ship.png"
                          alt="tear-ship"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-9 col-12">
                    <h2 class="theme_color text-uppercase fw-bold mb-0">
                      The Shifting Sands
                    </h2>
                    <p class="text-white">
                      This is the description of the area, including if its in
                      the water, land, cavern, etc. and any other warnings you
                      may need.
                    </p>
                    <div
                      class="
                        d-lg-flex d-inline-block
                        justify-content-between
                        typesMobile_design
                        mt-1
                        w-100
                      "
                    >
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Type</p>
                        <p class="text-white">Name Type</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Area</p>
                        <p class="text-white">Name Area</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Tribe</p>
                        <p class="text-white">Name Tribe</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Level</p>
                        <p class="text-white">1</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Distance</p>
                        <p class="text-white">
                          123 <span class="text-lowercase">ml</span>
                        </p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Reward</p>
                        <p class="text-white">N 1000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-5 mb-lg-5 mb-md-5 mb-0 mt-4">
            <div class="col-12 ps-0">
              <div class="innerFrame frame_main">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="story_img">
                      <div class="cardImgSection img_small">
                        <img
                          src="/images/tear-ship.png"
                          alt="tear-ship"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-9 col-12">
                    <h2 class="theme_color text-uppercase fw-bold mb-0">
                      The Lost City
                    </h2>
                    <p class="text-white">
                      This is the description of the area, including if its in
                      the water, land, cavern, etc. and any other warnings you
                      may need.
                    </p>
                    <div
                      class="
                        d-lg-flex d-inline-block
                        justify-content-between
                        typesMobile_design
                        mt-1
                        w-100
                      "
                    >
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Type</p>
                        <p class="text-white">Name Type</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Area</p>
                        <p class="text-white">Name Area</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Tribe</p>
                        <p class="text-white">Name Tribe</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Level</p>
                        <p class="text-white">1</p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Distance</p>
                        <p class="text-white">
                          123 <span class="text-lowercase">ml</span>
                        </p>
                      </div>
                      <div class="text-uppercase fw-bold">
                        <p class="theme_color mb-0">Reward</p>
                        <p class="text-white">N 1000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "../components/Sidebar.vue";
export default {
  name: "JourneySelection",
  components: {
    Sidebar,
  },
};
</script>
<style>
.theme_color {
  color: #0cf1ef !important;
}
.mainCard {
  padding: 30px;
  background-image: url("/images/long-frame.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cardImgSection {
  padding: 5px;
  background-image: url("/images/card-frame-1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
}
/* .story_img {
  width: 22%;
  position: absolute;
  top: 5px;
} */
.img_small {
  width: 90%;
  top: -10px;
}
.cardImgSection img {
  padding: 1rem;
  border-radius: 30px;
  width: 100%;
}
.progressbar {
  background-image: url("/images/progress-frame.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 80px;
}
.progressbar img {
  padding: 10px 45px;
  width: 50%;
  height: 60px;
  position: relative;
  top: 7px;
  left: 13px;
}
.innerFrame {
  background-image: url("/images/back-layer.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 30px;
}
.innerFrame h2 {
  font-size: 35px;
  padding-top: 20px;
}
.innerFrame p {
  font-size: 18px;
  margin: 0px;
}
.cardSpace {
  padding: 60px;
}
.frame_main {
  /* margin-top: 16px; */
  position: relative;
  /* left: -95px; */
  padding: 10px 60px 5px 10px;
  max-height: 310px;
}
.img_btn {
  position: absolute;
  left: 0px;
  z-index: 999;
  width: 16%;
  top: -28px;
  margin: 0 auto;
  display: block;
  width: 100%;
}
.img_btn .imgbtn_inner {
  margin: 0 auto;
  width: 50%;
}
.btnText {
  font-size: 1.1rem;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 26px;
  width: 100%;
  text-align: center;
}
</style>