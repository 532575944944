import axios from 'axios'
import Wallet from './Wallet';
import {
    useToast
} from 'vue-toastification'
const toast = useToast()
import {
    isWebUri
} from 'valid-url';
import moment from 'moment';
var laccount = null
if (localStorage.getItem("wax_user") != null) {
    laccount = localStorage.getItem("wax_user")
}
var chainUrl = process.env.VUE_APP_CHAIN_API;
var atomicAssetUrl = process.env.VUE_APP_API_ATOMICASSETS_URL;
var assetUrl = atomicAssetUrl + "assets";
// var chainDomain = process.env.VUE_APP_CHAIN_FULL_URL;
const setRPCUrl = () => {
    var rpcName = localStorage.getItem("anci-rpc");
    if (rpcName != null) {
        if (rpcName == "EOS Rio") {
            // chainDomain = `https://wax.eosrio.io`;
            chainUrl = `https://wax.eosrio.io/v1/chain`;
            atomicAssetUrl = `https://atomic.wax.eosrio.io/atomicassets/v1/`;
            assetUrl = atomicAssetUrl + "assets";
        } else if (rpcName == "pink.gg") {
            // chainDomain = `https://wax.pink.gg`;
            chainUrl = `https://wax.pink.gg/v1/chain`;
            atomicAssetUrl = `https://wax-aa.eosdac.io/atomicassets/v1/`;
            assetUrl = atomicAssetUrl + "assets";
        } else if (rpcName == "greymass") {
            // chainDomain = `https://wax.greymass.com`;
            chainUrl = `https://wax.greymass.com/v1/chain`;
            atomicAssetUrl = `https://wax.api.atomicassets.io/atomicassets/v1/`;
            assetUrl = atomicAssetUrl + "assets";
        } else if (rpcName == "nation.wax") {
            // chainDomain = `https://wax.api.eosnation.io`;
            chainUrl = `https://wax.api.eosnation.io/v1/chain`;
            atomicAssetUrl = `https://wax.api.atomicassets.io/atomicassets/v1/`;
            assetUrl = atomicAssetUrl + "assets";
        } else if (rpcName == "eosriobrazil") {
            // chainDomain = `https://wax.eosrio.io`;
            chainUrl = `https://wax.eosrio.io/v1/chain`;
            atomicAssetUrl = `https://atomic.wax.eosrio.io/atomicassets/v1/`;
            assetUrl = atomicAssetUrl + "assets";
        } else if (rpcName == "eosdacserver") {
            // chainDomain = `https://wax.eosdac.io`;
            chainUrl = `https://wax.eosdac.io/v1/chain`;
            atomicAssetUrl = `https://wax-aa.eosdac.io/atomicassets/v1/`;
            assetUrl = atomicAssetUrl + "assets";
        } else if (rpcName == "test") {
            // chainDomain = `https://testnet.wax.eosdetroit.io`;
            chainUrl = `https://testnet.wax.eosdetroit.io/v1/chain`;
            atomicAssetUrl = `https://test.wax.api.atomicassets.io/atomicassets/v1/`;
            assetUrl = atomicAssetUrl + "assets";
        }
    }
}
setRPCUrl();
if (localStorage.getItem("session-authenticator") != null) {
    new Wallet();
}
const getImgUrl = (hash, isVideo) => {
    if (isWebUri(hash)) {
        return hash;
    }
    let url = 'http://ipfs.io/ipfs/' + hash;
    if (isVideo) {
        return url
    }
    return '//images.weserv.nl/?url=' + url + "&w=300&h=300";
}
const callApi = async(params, callback) => {
    axios
        .get(`${assetUrl}?owner=${localStorage.getItem('wax_user')}&page=1&limit=100${params}`)
        .then(() => callback)
}
const setStake = async(dataParams, onSuccess, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
        const actionData = {
            account: "atomicassets",
            name: 'transfer',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams,
        }
        return await sendTransaction(actionData, onSuccess, onErrorCallback);
}
const resetOrClaim = async(action, asset_id, onSuccess) => {
    let userAccount = localStorage.getItem("wax_user")
    const actionData = {
        account: process.env.VUE_APP_CONTRACT,
        name: action,
        authorization: [{
            actor: userAccount,
            permission: 'active',
        }],
        data: {
            owner: userAccount,
            asset_id: asset_id,
        },
    };
    return await sendTransaction(actionData,onSuccess);
}
const getById = async(id, callback, onError) => {
    return await axios
        .get(`${assetUrl}/${id}`)
        .then(res => res.data.data)
        .then(callback)
        .catch(onError);
}
const getTable = async(table, lowerBound = null, callBack, onError) => {
    const activeUser = localStorage.getItem("wax_user");
    return await loggedInUser.rpc.get_table_rows({
        scope: activeUser,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 100,
        table: table,
        lower_bound: lowerBound
    }).then(async(res) => {
        if (res.rows.length > 0) {
            return res;
        }
        return [];
    }).then((res) => callBack(res)).catch(onError)
}
const tablePagination = async(table, key, callBack, onError) => {
    const activeUser = localStorage.getItem("wax_user");
    return await loggedInUser.rpc.get_table_rows({
        scope: activeUser,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 100,
        table: table,
        lower_bound: key
    }).then(async(res) => {
        if (res.rows.length > 0) {
            return res;
        }
        return [];
    }).then((res) => callBack(res)).catch(onError)
}
const getTable2 = async(table, lowerBound = null, callBack, onError) => {
    return await loggedInUser.rpc.get_table_rows({
        scope: process.env.VUE_APP_CONTRACT,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 100,
        table: table,
        lower_bound: lowerBound
    }).then(async(res) => {
        if (res.rows.length > 0) {
            return res;
        }
        return [];
    }).then((res) => callBack(res)).catch(onError)
}
const getStake = async(key, onSuccess) => {
    return await tablePagination("userstakes", key, async(result) => {
            if (result.rows !== undefined) {
                await result.rows.map(async(item, i) => {
                    await getById(item.asset_id, (asset) => {
                        result.rows[i]["asset"] = asset;
                        result.rows[i]["expiredDate"] = moment.utc(item.stake_time).format('YYYY-MM-DDTHH:mm:ss.SSSS');
                        result.rows[i]["currentUtc"] = moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSS');
                    });
                });

                return onSuccess(result);
            } else {
                return onSuccess([]);
            }
        },
        (error) => {
            toast.error(errorFormat(error));
            return error;
        })
}
const transaction = async(dataParams, onSuccessCallback) => {
    laccount = localStorage.getItem("wax_user");
    const actionData = {
        account: dataParams.contract,
        name: dataParams.action,
        authorization: [{
            actor: laccount,
            permission: 'active',
        }],
        data: dataParams.data,
    }
    return await sendTransaction(actionData, onSuccessCallback);
}
const sendTransaction = async (actionData, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        /*global loggedInUser:writable*/
        /*eslint no-undef: "error"*/
        return await loggedInUser.signTransaction({ actions: [actionData] }, { broadcast: true }).then((res) => {
            if (res.transactionId) {
                return { processed: res.transactionId }
            } else {
                return res;
            }
        }).then(onSuccessCallback)
    } catch (err) {
        if (typeof onErrorCallback === "function"){
            onErrorCallback(err);
        }else{
            let error = errorFormat(err);
            toast.error(error);
        }
    }
}
const errorFormat = (error) => {
    console.log(error.message, typeof error);
    if (typeof error == "string") {
        console.log(error);
        return "Something went wrong."
    }
    if (!error.error && error.message) {
        return error.message
    }
    if (error.error.what) {
        if (error.error.details) {
            return error.error.details[0].message
        }
        return error.error.what;
    } else {
        return "Transaction failed,try again."
    }
}
const actionCall = async(callback) => {
    return await axios
        .get(`https://wax.pink.gg/v2/history/get_actions?account=${localStorage.getItem('wax_user')}&filter=${process.env.VUE_APP_CONTRACT}%3Adailyclaim&skip=0&limit=1&sort=desc`)
        .then(res => res.data.actions)
        .then(callback)
}
const successMsg = (msg) => {
    if (msg) {
        toast.success(msg);
    } else {
        toast.success("Transaction successfull");
    }
}
const info = (msg) => {
    if (msg) {
        toast.info(msg);
    } else {
        toast.info("Transaction successfull");
    }
}
const market = async(callBack, lowerBound = null) => {
    return await loggedInUser.rpc.get_table_rows({
        scope: process.env.VUE_APP_CONTRACT,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        lower_bound: lowerBound,
        limit: 10,
        table: process.env.VUE_APP_TBL_MARKET
    }).then(async(res) => {
        const requests = res.rows.map(async(val, index) => {
            let nfts = await getByTempleteId(val.asset_t, (res) => res.data.data)
            res.rows[index].asset = nfts
            return val
        });
        return Promise.all(requests).then(() => {
            return res
        });
    }).then((res) => callBack(res))
}
const getByTempleteId = async(tmpId, callback) => {
    return axios
        .get(`${process.env.VUE_APP_API_TEMPLATE_URL}/ancientoasis/${tmpId}`)
        .then((res) => callback(res))
}
const buy = async(dataParams, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
        const actionData = {
            account: "luminascoins",
            name: 'transfer',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams,
        }
        return await sendTransaction(actionData, onSuccessCallback, onErrorCallback);
}
const getBalance = async (callBack) => {
    return await axios
        .post(`${chainUrl}/get_currency_balance`, {
            account: localStorage.getItem("wax_user"),
            code: "luminascoins",
            symbol: "NOLA",
        }).then(callBack)
}
export default {
    successMsg,
    errorFormat,
    getBalance,
    actionCall,
    resetOrClaim,
    transaction,
    callApi,
    getTable,
    getStake,
    setStake,
    market,
    info,
    getTable2,
    getImgUrl,
    buy
}