<template>
  <nav class="custom_nav navbar-expand-lg">
    <div class="container-fluid">
      <div class="d-flex justify-content-between nav_main position-relative">
        <div class="d-flex">
          <div class="leader_boardFrame position-relative leaderBoard">
            <img src="images/small-bar.png" alt="small-bar" class="img-fluid" />
            <a href="#" class="leaderText">LeaderBoard</a>
          </div>
          <div class="rank_frame position-relative">
            <img src="images/bar.png" alt="bar" class="img-fluid" />
            <span class="rankText">LEGENDARY VOYAGER</span>
            <p class="userRank">Rank</p>
          </div>
        </div>
        <div class="header_right_items d-flex">
          <div class="rank_frame position-relative right_frame">
            <img src="images/bar.png" alt="bar" class="img-fluid" />
            <span class="userPoints">100,000,000</span>
            <p class="rewardText">NOLA REWARDS</p>
          </div>
          <div class="leader_boardFrame position-relative dropdown">
            <img
              src="images/logout-button.png"
              alt="logout-button"
              class="img-fluid"
            />
            <a
              href="#"
              class="username"
              @click="isShow = !isShow"
              id="my-dropdown"
              data-toggle="dropdown"
              >user123
            </a>
            <div
              class="dropdown-menu custom-dpd-menu"
              :class="isShow ? 'show' : ''"
              aria-labelledby="my-dropdown"
            >
              <a class="dropdown-item" href="#" @click="logout()">logout</a>
            </div>
          </div>
        </div>
      </div>
      <a class="navbar-brand logo_img" href="https://ancientoasis.world">
        <img src="images/logo.png" alt="" class="img-fluid" />
      </a>
      <button
        class="navbar-toggler"
        @click="optionDropdown(isOpen)"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
        <span class="" role="button"
          ><i class="fa fa-bars" aria-hidden="true" style="color: #e6e6ff"></i
        ></span>
      </button>
      <div
        class="collapse navbar-collapse"
        :class="`${isOpen ? 'show' : ''}`"
        id="navbarText"
      >
        <!-- <span class="navbar-text">
          <Dropdown :logUser="authentication.user_name" :balance="balance" />
        </span> -->
      </div>
    </div>
  </nav>
</template>
<script>
// import Dropdown from "@/views/Dropdown";
import ApiService from "@/services";
import { reactive } from "vue";
import { mapGetters } from "vuex";
export default {
  name: "Navbar",
  computed: {
    ...mapGetters(["balance"]),
  },
  components: {
    // Dropdown,
  },

  data() {
    return {
      isOpen: false,
      isShow: false,
      dropdownItem: [
        {
          title: "Logout",
          link: "#",
        },
      ],
    };
  },
  mounted() {
    ApiService.getBalance((res) =>
      this.$store.commit("addBalance", res.data[0])
    );
  },
  methods: {
    optionDropdown(isOpen) {
      this.isOpen = !isOpen;
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
  },
  setup() {
    const authentication = reactive({
      user_name: localStorage.getItem("wax_user"),
    });
    return {
      authentication,
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
.logo {
  width: 100px;
  float: left;
}
.logo_img {
  width: 340px;
  margin: 0 auto;
  padding: 0px;
  position: relative;
  right: 0;
  top: -135px;
  display: block;
  text-align: center;
}
.logo_img img {
  width: 340px;
}

.custom_nav {
  font-family: "Montserrat", sans-serif;
  background-image: url("/images/header-frame.png");
  background-repeat: no-repeat;
  /* background-position: 100% 100%; */
  background-size: 100% 100%;
  padding-bottom: 15px;
  height: 135px;
  padding: 3%;
}
.nav-link {
  display: block;
  font-weight: bold;
  font-size: 20px;
}
.leader_boardFrame img {
  width: 220px;
  height: 60px;
}
.right_frame {
  width: 70%;
  margin-right: 10px;
}
.rank_frame img {
  width: 600px;
  margin-left: 10px;
  height: 75px;
  position: relative;
  /* top: -13px; */
}
.header_right_items {
  right: -43px;
  position: relative;
}
.header_right_items img {
  width: 600px;
  /* float: right; */
}
.header_right_items .leader_boardFrame img {
  width: 100%;
}
/* .leaderBoard {
  top: -10px;
} */
.nav_main {
  top: -45px;
}
.leaderBoard img {
  height: 45px;
}
.leaderText {
  position: absolute;
  left: 0;
  top: 12px;
  font-weight: bold;
  color: #00ffb9;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}
.rankText {
  position: absolute;
  left: -15px;
  top: 15px;
  font-weight: bold;
  color: #e5af62;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-size: 20px;
}
.userRank {
  position: absolute;
  left: -15px;
  top: 40px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-size: 14px;
}
.userPoints {
  position: absolute;
  left: 0px;
  top: 14px;
  font-weight: bold;
  color: #00ffb9;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-size: 22px;
}
.rewardText {
  position: absolute;
  left: 0px;
  top: 40px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-size: 14px;
}
.username {
  position: absolute;
  left: 10px;
  top: 21px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  width: 80%;
  font-size: 18px;
}

.custom-dpd-menu {
  background-color: #202732;
  min-width: 11rem;
  left: 22px;
  padding: 20px;
}
.custom-dpd-menu a {
  color: #fff !important;
  text-transform: capitalize;
  transition: all 0.4s;
}
.custom-dpd-menu a:hover {
  background-color: #6b5538 !important;
}
</style>