import {
    createRouter,
    createWebHistory
} from 'vue-router'
import Login from '../views/Login.vue'
import Journey from '../views/Journey'
import JourneySelection from '../views/JourneySelection'
import Shop from '../views/Shop'
import mainView from '../components/Main'
import moment from 'moment';
const routes = [{
        path: '/',
        redirect: '/dashboard',
        component: mainView,
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login')
            } else {
                next()
            }
        },
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            props: true,
            component: Journey,
            meta: {
                sideBarKey: 'Dashboard'
            }
        }],
    },
    {
        path: '/journey',
        component: mainView,
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login')
            } else {
                next()
            }
        },
        children: [{
            path: '/journey',
            name: 'journey',
            component: Journey,
            meta: {
                sideBarKey: 'journey'
            }
        }]
    },
    {
        path: '/journeyselection',
        component: mainView,
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login')
            } else {
                next()
            }
        },
        children: [{
            path: '/journeyselection',
            name: 'journeyselection',
            component: JourneySelection,
            meta: {
                sideBarKey: 'journeyselection'
            }
        }]
    },
    {
        path: '/shop',
        component: mainView,
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login')
            } else {
                next()
            }
        },
        children: [{
            path: '/shop',
            name: 'shop',
            component: Shop,
            meta: {
                sideBarKey: 'shop'
            }
        }]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
]
const isLogin = function () {
    if (localStorage.getItem('wax_user')) {
        var sessionDate = localStorage.getItem("ual-session-expiration");
        var d1 = new Date();
        var d2 = new Date(sessionDate);
        return moment(d2).isAfter(moment(d1))
    } else {
        return false
    }
}
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router